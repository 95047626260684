export const header = {
  lightBg: false,
  lightTitle: true,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Old School Dragon Nest',
  headline: 'Vanilla Experience',
  description: 'Embark on the Nostalgic Dragon Nest Journey.',
  buttonLabel: 'Get Started',
  imgStart: '',
  img: 'logov2.png',
  alt: 'dno-logo',
  hasSlider: true,
  hideButton: true,
  main: true,
}
export const multiserver = {
  lightBg: false,
  lightTitle: false,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Multiserver',
  headline: '',
  description:
    "Many regions covered, for the best experience across the world.",
  buttonLabel: 'Learn More',
  alt: 'multiserver',
  hasSlider: false,
  hasMap: true,
  hideButton: true,
  main: false,
};

export const regions = [
  { regionName: 'Europe West', regionNameSmall: 'EUW', regionID: 1, markerOffset: -30, name: "Frankfurt", coordinates: [9, 49] },
  { regionName: 'North America East', regionNameSmall: 'NAW', regionID: 2, markerOffset: -30, name: "Reston", coordinates: [-77, 38] },
  { regionName: 'North America West', regionNameSmall: 'NAE', regionID: 3, markerOffset: 25, name: "Hillsboro", coordinates: [-120, 43] },
  { regionName: 'South East Asia', regionNameSmall: 'SEA', regionID: 4, markerOffset: 25, name: "Singapore", coordinates: [103.8, 1] },
  { regionName: 'South America', regionNameSmall: 'SA', regionID: 5, markerOffset: 25, name: "Sao Paulo", coordinates: [-47, -25] },
  { regionName: 'Hong Kong', regionNameSmall: 'HK', regionID: 6, markerOffset: 25, name: "Hong Kong", coordinates: [114, 22] },
];

export const FaqData = [
  {
    title: "What does this server offer?",
    content: "DNOrigins perfectly recreates the classic Dragon Nest vibe, nailing most details. Skills, gameplay, everything feels just like it used to. It's the most authentic and optimized classic server out there, packed with unique QOL enhancements and performance tweaks to improve your experience."
  },
  {
    title: "What's the current level cap?",
    content: "The current level cap is 80."
  },
  {
    title: "How long has this server been running?",
    content: "Dragon Nest Origins was launched on September 25th, 2020."
  },
  {
    title: "Which regions can access Dragon Nest Origins?",
    content: "Dragon Nest Origins has a global presence. However, our multiserver currently supports: Europe, North America (West and East), South East Asia (including Singapore & Hong Kong), and South America. Our primary server and the development team are based in Europe."
  },
  {
    title: "Is multilingual support available on this server?",
    content: "Besides English, we offer limited & basic support for French, German & Chinese."
  },
  {
    title: "Is there a leveling system on this server? What's the leveling experience like?",
    content: "All dungeons come with five difficulty modes: easy, normal, hard, master, and abyss. Although our experience gain rates are enhanced, they aren't excessively high to allow instant leveling. The leveling process becomes smoother as accounts earn bonuses for maxing out characters. Main quests have had their experience & gold reward buffed, to motivate players to complete them."
  },
  {
    title: "How many classes are available? Is there custom balancing?",
    content: "The current available classes are: Warrior, Archer, Academic, Cleric, Sorceress, Kali, Assassin & Lancea. We haven't introduced custom balancing, and it's unlikely until 90 cap is in place."
  },
  {
    title: "Are there any pay to win elements?",
    content: "Absolutely none. Any costumes or cosmetics available do not provide stat advantages. Moreover, players can earn Circus Points from Circus maps, which can be exchanged for cash items."
  },
  {
    title: "Can you provide insights on the staff and developers?",
    content: "DNOrigins is led by Beauchamp, the developer, and supported by a few dedicated community managers, who contribute as their schedules allow. We are veteran Dragon Nest players committed to delivering a server that not only revives the classic version but also ensures it's accessible to everyone who cherishes the original experience. We're also partnered with a sister server, <a href=\"https://fatduckdn.com/\" target=\"_blank\">Project Duck</a>, which presents a unique approach to this game that might interest you."
  },
  {
    title: "What are the plans post 80 cap?",
    content: "Dragon Nest at 90 cap is a vastly different experience, and is no longer considered classic. After we reach the end of 80 cap, we plan to retain the T4 skill system and carefully adjust the balance as needed. While we'll be incorporating content from 90 cap and above, our aim is to preserve an experience that still echoes the classic game. Rest assured, the core nostalgic aspects of gameplay will remain fundamentally classic, making sure that the essence of what you loved about Dragon Nest continues."
  },
];

export const URLs = {
  discord: "//discord.gg/bmRdXRh",
  youtube: "//www.youtube.com/channel/UCnShvWI6CvzoeM-GkJq4Aiw",
  directdl: "https://drive.google.com/file/d/1kcgTCUjXVos3CS_3X8u4aW14No4FRUQb/view?usp=sharing",
  installer1:"https://legacy.dnorigins.com/installer/DNOriginsInstaller.zip",
}

export const sendRewardsInfo = [
  { name: 'Receive Type Code', type: 'number', id: "receiveTypeCode", placeholder: "1: All Items, 2: Select one item" },
  { name: 'Target Type Code', type: 'number', id: "targetTypeCode", placeholder: "1: All accounts, 2: List of accounts, 3: All characters, 4: List of characters" },
  { name: 'Account ID List', type: 'text', id: "accountIDList", placeholder: "Format: 2408,2409,... Not necessary if Target Type Code is not 2!" },
  { name: 'Account Name List', type: 'text', id: "accountNameList", placeholder: "Format: account1,account2,... Not necessary if Target Type Code is not 2!" },
  { name: 'Character ID List', type: 'text', id: "characterIDList", placeholder: "Format: 2408,2409,... Not necessary if Target Type Code is not 4!" },
  { name: 'Character Name List', type: 'text', id: "characterNameList", placeholder: "Format: testchar1,testchar2,... Not necessary if Target Type Code is not 4!" },
  { name: 'Target World ID', type: 'number', id: "targetWorldID", value: 1},
  { name: 'Target Class Code', type: 'number', id: "targetClassCode", placeholder: "Allowed JobID. Leave empty for no class limit!" },
  { name: 'Target Min Level', type: 'number', id: "targetMinLevel", value: 1 },
  { name: 'Target Max Level', type: 'number', id: "targetMaxLevel", value: 60 },
  { name: 'Send Date', type: 'datetime-local', id: "sendDate"},
  { name: 'Expiration Date', type: 'datetime-local', id: "expirationDate", },
  { name: 'Event Name', type: 'text', id: "eventName", placeholder: "Does not show in game"},
  { name: 'Sender Name', type: 'text', id: "senderName", placeholder: "Sender Name to display"},
  { name: 'Message Content', type: 'text', id: "messageContent", placeholder: "Message to display"},
  { name: 'Gold Reward', type: 'number', id: "goldReward" },
  { name: 'Event Reward Type Code', type: 'number', id: "eventRewardTypeCode", placeholder: "1: GM, 2: System"},
]

export const itemRewardInfo = [
  { name: 'Item ID', type: 'number', id: "itemID", placeholder: "Enter item ID" },
  { name: 'Product Flag', type: 'number', id: "productFlag", placeholder: "0 for False, 1 for True" },
  { name: 'Count', type: 'number', id: "count", placeholder: "Count" },
  { name: 'Durability', type: 'number', id: "durability", placeholder: "Durability" },
  { name: 'Random seed', type: 'number', id: "randomSeed", placeholder: "Random Seed" },
  { name: 'Enhancement Level', type: 'number', id: "enhanceLevel", placeholder: "Enhancement Level" },
  { name: 'Potential', type: 'number', id: "potential", placeholder: "Potential" },
  { name: 'Soul Bound', type: 'number', id: "soulBound", placeholder: "Soul Bound"},
  { name: 'Seals', type: 'number', id: "seals", placeholder: "Seals"},
  { name: 'Option', type: 'number', id: "option", placeholder: "Option"},
  { name: 'Lifespan', type: 'number', id: "lifespan", placeholder: "Lifespan" },
  { name: 'Eternity', type: 'number', id: "eternity", placeholder: "Eternity"},
  { name: 'Jewel Type', type: 'number', id: "jewelType", placeholder: "Jewel Type"},
]

export const jobCodes = {
  1: "Warrior",
  2: "Archer",
  3: "Sorceress",
  4: "Cleric",
  5: "Academic",
  6: "Kali",
  7: "Assassin",
  8: "Lancea",
  9: "Machina",
  10: "Ancient",
  11: "Swordsman",
  12: "Mercenary",
  14: "Sharpshooter",
  15: "Acrobat",
  17: "Elementalist",
  18: "Arcanist",
  19: "Warlock",
  20: "Paladin",
  21: "Monk",
  22: "Priest",
  23: "Gladiator",
  24: "Moonlord",
  25: "Barbarian",
  26: "Destroyer",
  29: "Sniper",
  30: "Sentinel",
  31: "Tempest",
  32: "Wind Walker",
  35: "Seleana",
  36: "Glaciana",
  37: "Illumia",
  38: "Obscuria",
  41: "Guardian",
  42: "Crusader",
  43: "Saint",
  44: "Inquisitor",
  45: "Exorcist",
  46: "Engineer",
  47: "Shooting Star",
  48: "Gear Master",
  49: "Alchemist",
  50: "Adept",
  51: "Physician",
  54: "Screamer",
  55: "Dark Summoner",
  56: "Soul Eater",
  57: "Dancer",
  58: "Blade Dancer",
  59: "Spirit Dancer",
  62: "Chaser",
  63: "Ripper",
  64: "Raven",
  67: "Bringer",
  68: "Light Fury",
  69: "Abyss Walker",
  72: "Dragoon",
  73: "Flurry",
  74: "Valkyrie",
  75: "Avenger",
  76: "Dark Avenger",
  77: "Patrona",
  78: "Defensio",
  79: "Ruina",
  80: "Hunter",
  81: "Silver Hunter",
  82: "Heretic",
  83: "Arch Heretic",
  84: "Mara",
  85: "Black Mara",
  86: "Mechanic",
  87: "Ray Mechanic",
  88: "Oracle",
  89: "Oracle Elder",
  90: "Phantom",
  91: "Bleed Phantom",
  92: "Knightess",
  93: "Avalanche",
  94: "Randgrid",
  95: "Launcher",
  96: "Impactor",
  97: "Buster",
  98: "Plaga",
  99: "Vena Plaga",
  100: "Treasure Hunter",
  102: "Duelist",
  103: "Trickster",
  106: "Knight",
  107: "Mystic Knight",
  108: "Grand Master"
};