import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactLoading from 'react-loading';
import {
  Container,
  Typography,
  TextField,
  Button,
  Snackbar,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  Box,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import AssessmentIcon from '@material-ui/icons/Assessment';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
} from 'recharts';

const config = require('../Config/Config');

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: 'Roboto, sans-serif',
    backgroundColor: '#1b1b1b',
    padding: theme.spacing(4, 0),
    minHeight: '100vh',
  },
  pageContainer: {
    margin: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#ffffff',
    padding: theme.spacing(4),
    width: '90%',
    maxWidth: 1200,
    [theme.breakpoints.up('sm')]: {
      width: '85%',
    },
    [theme.breakpoints.up('md')]: {
      width: '75%',
    },
    textAlign: 'center',
    border: '2px solid #ffbb00',
    borderRadius: theme.spacing(1),
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.7)',
    backgroundColor: '#2c2c2c',
  },
  title: {
    color: '#ffbb00',
    textAlign: 'center',
    marginBottom: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  formContainer: {
    width: '100%',
    marginBottom: theme.spacing(4),
  },
  inputField: {
    backgroundColor: '#3a3a3a',
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(2),
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
      },
    },
    '& .MuiInputBase-input': {
      color: '#ffffff',
    },
    '& .MuiInputLabel-root': {
      color: '#ffffff',
    },
  },
  selectField: {
    backgroundColor: '#3a3a3a',
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(2),
    width: '100%',
    '& .MuiInputBase-root': {
      color: '#ffffff',
    },
    '& .MuiInputLabel-root': {
      color: '#ffffff',
    },
    '& .MuiSelect-icon': {
      color: '#ffffff',
    },
  },
  checkboxContainer: {
    marginBottom: theme.spacing(2),
    color: '#ffffff',
  },
  button: {
    width: '250px',
    height: '50px',
    backgroundColor: '#ffbb00',
    color: '#000000',
    padding: theme.spacing(1.5, 6),
    '&:hover': {
      backgroundColor: '#e0a800',
    },
    marginTop: theme.spacing(2),
    alignSelf: 'center',
    textTransform: 'none',
    fontSize: '1rem',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '20vh',
  },
  chartContainer: {
    width: '100%',
    height: 600,
    marginTop: theme.spacing(4),
  },
  tooltipStyle: {
    backgroundColor: '#2c2c2c',
    border: 'none',
    color: '#ffffff',
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
  },
}));

const JobPerformance = (props) => {
  const classes = useStyles();

  const [mapIDs, setMapIDs] = useState('14551,14552,14553');
  const [monsterID, setMonsterID] = useState(-1);
  const [useSpecificMonsterID, setUseSpecificMonsterID] = useState(true);
  const [damageDecile, setDamageDecile] = useState(25);
  const [loading, setLoading] = useState(false);
  const [performanceData, setPerformanceData] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbarOpen(false);
  };

  const handleFetchPerformance = async () => {
    if (!mapIDs.trim()) {
      setSnackbarSeverity('warning');
      setSnackbarMessage('Please provide at least one Map ID.');
      setSnackbarOpen(true);
      return;
    }

    if (useSpecificMonsterID && (monsterID === '' || monsterID === null)) {
      setSnackbarSeverity('warning');
      setSnackbarMessage('Please provide a valid Monster ID.');
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);

    const payload = {
      mapIDs: mapIDs.trim(),
      monsterID: useSpecificMonsterID ? monsterID : -1,
      useSpecificMonsterID: useSpecificMonsterID,
      damageDecile: damageDecile,
    };

    try {
      const response = await fetch('/api/admin/jobPerformance', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (response.ok && data.resultCode === 200) {
        const transformedData = data.results.data.map((item) => ({
          JobName: item[0].value,
          TotalAppearances: item[1].value,
          AvgDamage: parseInt(item[2].value.replace(/,/g, ''), 10),
          FirstPlaceFinishes: item[3].value,
          TopThreeFinishes: item[4].value,
        }));

        setPerformanceData(transformedData);
        setSnackbarSeverity('success');
        setSnackbarMessage('Job performance data fetched successfully.');
      } else {
        setSnackbarSeverity('error');
        setSnackbarMessage(data.results.error || 'Failed to fetch job performance data.');
      }
    } catch (error) {
      console.error('Error fetching job performance:', error);
      setSnackbarSeverity('error');
      setSnackbarMessage('An error occurred. Please try again later.');
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  const damageDecileOptions = [
    { label: 'Top 25% Performers', value: 75 },
    { label: 'Top 50% Performers', value: 50 },
    { label: 'Top 75% Performers', value: 25 },
    { label: 'Top 90% Performers', value: 10 },
    { label: 'Count All', value: 0 },
  ];

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className={classes.tooltipStyle}>
          <Typography variant="subtitle1">{label}</Typography>
          {payload.map((entry, index) => (
            <Typography key={`item-${index}`} variant="body2" style={{ color: entry.color }}>
              {`${entry.name}: ${entry.value.toLocaleString()}`}
            </Typography>
          ))}
        </div>
      );
    }

    return null;
  };

  return (
    <>
      {config.isAllowed(props, config.accountLevelCodeTable.moderator) && (
        <div className={classes.root}>
          <Container className={classes.pageContainer}>
            <Typography variant="h4" className={classes.title}>
              <AssessmentIcon fontSize="large" /> Job Performance
            </Typography>

            <Box className={classes.formContainer}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    label="Map IDs (Comma Separated)"
                    placeholder="e.g., 1,2,3"
                    value={mapIDs}
                    onChange={(e) => setMapIDs(e.target.value)}
                    className={classes.inputField}
                    fullWidth
                    InputLabelProps={{
                      style: { color: '#ffffff' },
                    }}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        handleFetchPerformance();
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    label="Monster ID"
                    type="number"
                    value={monsterID}
                    onChange={(e) => setMonsterID(parseInt(e.target.value, 10))}
                    className={classes.inputField}
                    fullWidth
                    InputLabelProps={{
                      style: { color: '#ffffff' },
                    }}
                    disabled={!useSpecificMonsterID}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        handleFetchPerformance();
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" className={classes.selectField} fullWidth>
                    <InputLabel style={{ color: '#ffffff' }}>Damage Decile</InputLabel>
                    <Select
                      value={damageDecile}
                      onChange={(e) => setDamageDecile(e.target.value)}
                      label="Damage Decile"
                    >
                      {damageDecileOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>

            <Button
              variant="contained"
              className={classes.button}
              onClick={handleFetchPerformance}
              disabled={loading}
            >
              {loading ? (
                <ReactLoading type="spin" color="#000000" height={20} width={20} />
              ) : (
                'Fetch Performance'
              )}
            </Button>

            {performanceData && performanceData.length > 0 && (
              <div className={classes.chartContainer}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    data={performanceData}
                    margin={{ top: 20, right: 30, left: 20, bottom: 150 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" stroke="#444" />
                    <XAxis
                      dataKey="JobName"
                      stroke="#ffffff"
                      angle={-45}
                      textAnchor="end"
                      interval={0}
                      height={150}
                    />
                    <YAxis
                      stroke="#ffffff"
                      tickFormatter={(value) =>
                        value >= 1e9
                          ? `${(value / 1e9).toFixed(1)}B`
                          : value >= 1e6
                          ? `${(value / 1e6).toFixed(1)}M`
                          : value
                      }
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend verticalAlign="top" height={36} />
                    <Bar dataKey="AvgDamage" fill="#8884d8" name="Average Damage" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            )}
          </Container>

          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity={snackbarSeverity}
              elevation={6}
              variant="filled"
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </div>
      )}
    </>
  );
};

export default JobPerformance;
